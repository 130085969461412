<template>
  <v-lazy>
    <div>
      <div>
        <template>
          <l-map
            style="height: 200px; width: 100%"
            class="mx-2 d-flex align-end flex-column"
            :zoom="16"
            :center="[transfer.to_magazine.lat, transfer.to_magazine.lng]"
          >
            <v-btn
              style="z-index: 5000; margin: 10px"
              class="mx-2"
              fab
              color="primary"
              target="_blank"
              :href="`https://www.google.com/maps/search/?api=1&query=${
                (transfer.from_magazine.lat, transfer.from_magazine.lng)
              }`"
            >
              <v-icon dark> mdi-google-maps </v-icon>
            </v-btn>
            <l-polyline
              color="green"
              :lat-lngs="[
                [
                  { lat: transfer.to_magazine.lat, lng: transfer.to_magazine.lng },
                  { lat: transfer.from_magazine.lat, lng: transfer.from_magazine.lng },
                ],
              ]"
              @click="alert(item)"
            />
            <l-tile-layer :url="url"></l-tile-layer>
            <l-marker :lat-lng="[transfer.to_magazine.lat, transfer.to_magazine.lng]">
              <l-tooltip :options="{ permanent: true }">
                {{ transfer.to_magazine.name }}
              </l-tooltip>
            </l-marker>
            <l-marker :lat-lng="[transfer.from_magazine.lat, transfer.from_magazine.lng]">
              <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" icon-url="apartment.png" />

              <l-tooltip :options="{ permanent: true }">
                {{ transfer.from_magazine.name }}
              </l-tooltip>
            </l-marker>
          </l-map>
        </template>
      </div>
      <div>
        <h2>{{ translate('Transfer Info') }}</h2>
        <v-divider></v-divider>
        <v-row dense>
          <v-col
            ><span>{{ translate('Created at') }}</span>
            <span
              ><h3>{{ formatDate(transfer.transfer_created_at) }}</h3></span
            ></v-col
          >
          <v-col v-if="transfer.driver_picked_up_at != null"
            ><span>{{ translate('Driver Picked up at') }}</span>
            <span
              ><h3>{{ formatDate(transfer.driver_picked_up_at) }}</h3></span
            ></v-col
          >
          <v-col v-if="transfer.driver_delivered_at != null"
            ><span>{{ translate('Driver Delivered at') }}</span>
            <span
              ><h3>{{ formatDate(transfer.driver_delivered_at) }}</h3></span
            ></v-col
          >

          <v-col style="margin: 5px"
            ><span>{{ translate('Status') }} </span>
            <span class="font-weight-medium v-chip v-size--small" :class="orderStatusToColor(transfer.transfer_status)"
              ><span class="v-chip__content"> {{ orderStatusToText(transfer.transfer_status) }} </span></span
            >
            <v-col class="pa-0">
              <v-select
                style="padding-top: 10px"
                outlined
                dense
                :hide-details="true"
                class="shrink"
                :label="translate('change Transfer Status')"
                @input="name => transferStatusChanged(name)"
                :items="orderStatuses"
                :value="transfer.transfer_status"
              ></v-select>
            </v-col>
          </v-col>
        </v-row>

        <br />
        <h3>{{ translate('Stocks') }}</h3>
        <v-data-table
          dense
          :items-per-page="20"
          :headers="stocksTableHeaders"
          :items="transfer.stocks"
          :hide-default-footer="true"
          item-key="name"
        >
          <template v-slot:item.amount="{ item }">{{ item.amount }} {{ item.measurement_name }}</template>
        </v-data-table>
      </div>
      <br />
      <br />

      <v-divider></v-divider>
      <br />
      <h3>{{ translate('Driver') }}</h3>

      <v-data-table
        v-if="transfer.driver_id != null"
        :items-per-page="20"
        dense
        :headers="driverTableHeaders"
        :items="[transfer]"
        :hide-default-footer="true"
        item-key="name"
      ></v-data-table>
    </div>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from 'vue2-leaflet'
import { Icon } from 'leaflet'
import { mdiGoogle } from '@mdi/js'

import axios from 'axios'
import {
  assignTransferToDriver,
  changeTransferStatus,
  getApprovedDrivers,
  getOrders,
  getTransfers,
  getUsers,
  insertNewOrderImage,
  removeOrderImage,
} from '@/ApiManager'
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  props: ['transfer', 'magazines'],

  methods: {
    getMagazine(magazineId) {
      for (var i = 0; i < this.magazines.length; i++) {
        if (this.magazines[i].id == magazineId) {
          return this.magazines[i]
        }
      }
    },
    async getNewData() {
      this.$store.state.loading = true

      var transfers = await getTransfers()
      for (var i = 0; i < transfers.length; i++) {
        transfers[i].from_magazine = this.getMagazine(transfers[i].magazine_from_id)
        transfers[i].to_magazine = this.getMagazine(transfers[i].magazine_to_id)
      }
      this.$emit('setNewData', transfers)
      this.$store.state.loading = false
    },
    async transferStatusChanged(orderStatus) {
      this.$store.state.loading = true

      await changeTransferStatus(this.transfer.id, orderStatus)
      this.getNewData()
    },
    async assignTransferToDriver() {
      this.$store.state.loading = true

      if (this.selected_driver == null) {
        await assignTransferToDriver(this.transfer.id, null)
        this.getNewData()
        return
      }
      await assignTransferToDriver(this.transfer.id, this.selected_driver.id)
      this.getNewData()
    },
  },
  data() {
    return {
      file: null,
      orderStatuses: [
        { text: this.translate('Waiting for a Driver'), value: 'waiting_for_a_driver' },
        { text: this.translate('Driver Picking up'), value: 'driver_picking_up' },
        { text: this.translate('Driver Delivering'), value: 'driver_delivering' },
        { text: this.translate('Completed'), value: 'completed' },
      ],
      drivers: [],
      selected_driver: this.transfer.driver_name,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      zoom: 50,
      markerLatLng: [0, 0],
      leaflet_key: 0,
      clientTableHeaders: [
        { text: this.translate('Name'), value: 'client_name' },
        { text: this.translate('Phone Number'), value: 'phone_number' },
        { text: this.translate('Address'), value: 'address_note' },
      ],
      driverTableHeaders: [
        { text: 'ID', value: 'driver_id' },

        { text: this.translate('Name'), value: 'driver_name' },
        { text: this.translate('Phone Number'), value: 'driver_phone_number' },
      ],
      stocksTableHeaders: [
        { text: this.translate('Product'), value: 'product_name' },
        { text: this.translate('Amount'), value: 'amount' },
      ],
    }
  },
  computed: {
    getDrivers() {
      var data = []
      data = this.drivers
      data.push({ text: 'none', value: null })
      return data
    },
    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5]
    },
  },
  components: {
    LMap,
    mdiGoogle,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
  },
  async created() {
    this.$store.state.loading = true

    var _drivers = await getApprovedDrivers()
    for (var i = 0; i < _drivers.length; i++) {
      this.drivers.push({
        text: _drivers[i].name,
        value: _drivers[i],
      })
    }
    this.$store.state.loading = false
  },
}
</script>
<style scoped>
.customBtn {
  height: 25px;
  width: 25px;
}
.v-input {
  font-size: 0.8rem;
}
.v-input .v-label {
  font-size: 1rem;
}
</style>