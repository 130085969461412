<template>
  <div>
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 80%"></div>
      <v-select
        class="pa-2"
        outlined
        filled
        dense
        v-model="dateRange"
        @input="dateRange => dateRangeChanged(dateRange)"
        :items="['This Month', 'Last Month', 'Today', 'Yesterday', 'This Year', 'Last Year', 'Custom Date']"
      ></v-select>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Custom Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate('Transfers') }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        sort-by.sync="transfer_status"
        sort-desc.sync="true"
        :items-per-page="20"
        :search="search"
        :headers="headers"
        :items="getTransfers"
        class="elevation-1"
      >
        <template v-slot:item.id="{ item }">
          <v-dialog width="50%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                <span style="font-weight: bold">#{{ item.id }}</span>
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ translate('Transfer') }} #{{ item.id }}</v-card-title>
              <v-card-text>
                <TransferInfo @setNewData="setNewData" :magazines="magazines" :transfer="item"></TransferInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.transfer_status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="orderStatusToColor(item.transfer_status)"
            ><span class="v-chip__content"> {{ orderStatusToText(item.transfer_status) }} </span></span
          >
        </template>
        <template v-slot:item.transfer_created_at="{ item }">
          <span>{{ formatDate(item.transfer_created_at) }}</span
          >&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"
            ><span class="v-chip__content"> {{ timeAgo(item.transfer_created_at) }} </span></span
          >
        </template>
        <template v-slot:item.from_magazine="{ item }">
          <span>{{ item.from_magazine.name }}</span>
        </template>
        <template v-slot:item.to_magazine="{ item }">
          <span>{{ item.to_magazine.name }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', params: { id: item.id } }">
            <!--
             <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
           -->
          </router-link>
          <v-dialog v-model="delete_dialog" :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click.stop="
                  editing_item = JSON.parse(JSON.stringify(item))
                  item.delete_dialog = true
                "
                small
                style="color: var(--v-error-base)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5"
                >{{ translate('Are you sure want to delete Transfer') }} {{ item.id }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{ translate('Cancel') }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{ translate('OK') }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <downloadExcel
        class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile"
        :data="getTransfers"
        :fields="{
          id: 'id',
          transfer_created_at: 'transfer_created_at',
          driver_name: 'driver_name',
          from_magazine: 'from_magazine.name',
          to_magazine: 'to_magazine.name',
          transfer_status: 'transfer_status',
        }"
      >
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import { deleteTransfer, getCurrency, getMagazines, getOrders, getTransfers } from '@/ApiManager'
import TransferInfo from './TransferInfo.vue'
export default {
  setup() {
    const edit_created_date = ref('')
    const edit_customer_name = ref('')
    const edit_customer_status = ref('')
    const edit_magazine = ref('')
    const edit_customer_total_price = ref('')

    return {
      edit_created_date,
      edit_customer_name,
      edit_customer_status,
      edit_magazine,
      edit_customer_total_price,
    }
  },
  computed: {
    getTransfers() {
      return this.transfers
    },
  },
  data() {
    return {
      dateRange: 'This Month',
      date: null,
      menu: null,
      infoDialogItem: { id: 0 },
      infoDialog: false,
      search: '',
      dialog: false,
      delete_dialog: false,
      currency: '',
      magazine_names: [],
      single_name: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: this.translate('Created Date'), value: 'transfer_created_at' },
        { text: this.translate('From Magazine'), value: 'from_magazine' },
        { text: this.translate('To Magazine'), value: 'to_magazine' },
        { text: this.translate('Transfer Status'), value: 'transfer_status' },

        { text: this.translate('Actions'), value: 'actions' },
      ],
      transfers: [],
      magazines: [],
    }
  },
  components: { TransferInfo, TransferInfo },
  methods: {
    async dateRangeChanged(dateRange, customDate) {
      if (dateRange == 'Custom Date' && customDate == undefined) return
      this.$store.state.loading = true
      if (customDate != undefined) {
        this.dateRange = 'Custom Date'
      }
      var transfers = await getTransfers(dateRange)
      for (var i = 0; i < transfers.length; i++) {
        transfers[i].from_magazine = this.getMagazine(transfers[i].magazine_from_id)
        transfers[i].to_magazine = this.getMagazine(transfers[i].magazine_to_id)
      }
      this.transfers = transfers
      this.$store.state.loading = false
    },
    openInfoDialog(item) {
      this.infoDialogItem = item
      this.infoDialog = true
    },
    getMagazine(magazineId) {
      for (var i = 0; i < this.magazines.length; i++) {
        if (this.magazines[i].id == magazineId) {
          return this.magazines[i]
        }
      }
    },
    setNewData(data) {
      this.transfers = data
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name) this.edit_magazine = this.magazine_names[i].id
      console.log(this.edit_magazine)
    },
    handleDelete(id) {
      deleteTransfer(id)
      this.delete_dialog = false
      this.transfers = this.transfers.filter(function (item) {
        return item.id != id
      })
    },
  },
  async created() {
    this.$store.state.loading = true
    getCurrency().then(response => {
      this.currency = response['currency']
    })
    var transfers = await getTransfers(this.dateRange)
    this.magazines = await getMagazines()
    for (var i = 0; i < transfers.length; i++) {
      transfers[i].from_magazine = this.getMagazine(transfers[i].magazine_from_id)
      transfers[i].to_magazine = this.getMagazine(transfers[i].magazine_to_id)
    }
    this.transfers = transfers
    this.$store.state.loading = false
  },
}
</script>

<style>
a {
  text-decoration: none;
}
</style>
